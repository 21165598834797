<ng-template #actionTemplate>
  @if (!overflow) {
  <div class="tuula-table-row-action-button" [class.disabled]="disabled()" (click)="handleClick()">
    <i [tooltip]="name() | translate" placement="bottom" tooltipDelay="100" class="ai-icon-{{icon()}}"></i>
  </div>
  } @else {
  <div class="tuula-table-row-action-menu-item" [class.disabled]="disabled()" (click)="handleClick()">
    <i class="ai-icon-{{icon()}} tuula-action-icon"></i>
    <span class="tuula-action-text">{{name() | translate}}</span>
  </div>
  }
</ng-template>
