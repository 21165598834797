import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { Button, CheckBox, SidesheetOption, SelectBoxes, SideSheetConfig, SideSheetContent } from '../../../models/side-sheet';
import { SideSheetService } from '../../../services';
import { Subscription } from 'rxjs';
import { NgClass, NgFor, NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatLabel } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-sidesheet-commandline',
  templateUrl: './sidesheet-commandline.component.html',
  styleUrls: ['./sidesheet-commandline.component.scss'],
  standalone:true,
  imports: [NgIf,NgClass,TranslateModule,NgStyle,
    MatIconModule,MatProgressSpinnerModule,
    MatCheckboxModule,MatLabel,MatSelectModule,
    MatOptionModule,FormsModule,NgFor,NgTemplateOutlet]
})
export class SidesheetCommandlineComponent implements OnInit, OnDestroy {

  @Input() sideSheetConfig: SideSheetConfig;
  @Input() sideSheetContent: SideSheetContent;
  @Input() commandLineTemplate: TemplateRef<any>;

  @Output() buttonClicked = new EventEmitter<Button>();
  @Output() checkboxSelected = new EventEmitter<CheckBox>();
  @Output() dropdownSelected = new EventEmitter<{dropdown: SelectBoxes, option: SidesheetOption}>();

  selectedItems: string[] = [];

  private subscription: Subscription = new Subscription();


  constructor(private sideSheetService: SideSheetService) {}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onCommanlineButtonClicked(button: Button) {
    this.onExecuteButtonAction(button);
  }

  onExecuteButtonAction(button: Button) {
    this.sideSheetService.closeSidesheetPopup(null);
    this.buttonClicked.emit(button);
  }

  onExecuteDropdownAction(item: SelectBoxes, option: SidesheetOption) {
    this.sideSheetService.closeSidesheetPopup(null);
    if (!item.selectedItem) item.selectedItem = [];
    item.selectedItem.push(option);
    item.selectedItem = this.convertUniqueArray(item.selectedItem, 'key');
    item.options.map((data) => (data.selected = false));
    
    this.dropdownSelected.emit({dropdown: item, option});
  }

  onExecuteCheckboxAction(checked: any,item: CheckBox) {
    this.sideSheetService.closeSidesheetPopup(null);
    item.checked = checked;

    this.checkboxSelected.emit(item);    
  }
 
  convertUniqueArray(arr: any[], filter: string) {
    return [...new Map(arr.map((item) => [item[filter], item])).values()];
  }

}
