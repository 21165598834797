<div class="sidesheet-overlay" *ngIf="sidesheetPopupRef"></div>
<div class="sidesheet-tooltip-container" *ngIf="sidesheetPopupRef">

  @if (sidesheetPopupRef?.content?.confirmAction) {
    <ng-container
      *ngTemplateOutlet="confirmActionTemplate"></ng-container>
  } @else {
    <ng-container
      *ngTemplateOutlet="sidesheetPopupRef?.content?.template"></ng-container>
  }
</div>

<ng-template #confirmActionTemplate>

  <span [ngStyle]="sidesheetPopupRef?.content?.confirmAction?.contentStyle">
    <div class="sidesheet-tooltip-header" [ngStyle]="sidesheetPopupRef?.content?.confirmAction?.headerStyle">
      <div class="sidesheet-tooltip-icon">
        <img src="/assets/icons/notify.svg" alt="">
      </div>
      <div class="sidesheet-tooltip-close" (click)="onClose()">
        <img src="/assets/icons/close.svg" alt="">
      </div>
    </div>
    <div class="sidesheet-tooltip-header-text"> {{ sidesheetPopupRef?.content?.confirmAction?.textHeader | translate }}</div>
    <div class="sidesheet-tooltip-text">
      {{ sidesheetPopupRef?.content?.confirmAction?.textContent | translate }}
    </div>

    <div class="sidesheet-tooltip-footer">
      <button class="c4p-button-cancel sidesheet-btn-cancel" *ngIf="sidesheetPopupRef?.content?.confirmAction?.textCancel" type="submit"
              (click)="onCancel()">
        {{sidesheetPopupRef?.content?.confirmAction?.textCancel | translate}}
      </button>
      <button class="c4p-button sidesheet-mark-button" type="submit" *ngIf="sidesheetPopupRef?.content?.confirmAction?.textOk"
              (click)="onOk()">
        <img src="/assets/icons/check-white.svg" alt="" />
        {{ sidesheetPopupRef?.content?.confirmAction?.textOk | translate }}
      </button>
    </div>
  </span>
</ng-template>
