import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
  standalone: true,
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit: number = 250, ellipsis: string = '...'): string {
    if (value.length <= limit) {
      return value; // Return the full text if it's shorter than the limit
    }

    // Truncate to the last space before the limit
    let truncatedText = value.slice(0, limit);
    const lastSpaceIndex = truncatedText.lastIndexOf(' ');

    if (lastSpaceIndex > 0) {
      truncatedText = truncatedText.slice(0, lastSpaceIndex); // Truncate at the last space
    }

    return truncatedText + ellipsis; // Add ellipsis
  }
}