import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataWrapper } from '../../models/gatekeeper/data-wrapper.model';
import { GatekeeperModel } from '../../models/gatekeeper/gatekeeper.model';
import { LanguageService } from '../../services/language/language.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export interface IGatekeeper {
  // Popup dialog title
  title: string;

  // Reason label, if it is not provided then we have popup without reason
  reason_label?: string;

  // Checkbox label, if it is not provided then we have popup without check list
  checkbox_label?: string;

  // Check list
  checkbox_description?: string;

  // Message for checkbox
  checkbox_message?: string;

  // OK button text
  confirm_text?: string;
}

@Component({
  selector: 'app-gatekeeper',
  templateUrl: './gatekeeper.component.html',
  styleUrls: ['./gatekeeper.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class GatekeeperComponent implements OnInit {
  public gatekeeperForm: FormGroup = null!;
  public selectedLanguage: number;
  public lines = [1, 2, 3, 4, 5, 6];

  constructor(
    public dialogRef: MatDialogRef<GatekeeperComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: GatekeeperModel,
    public languageService: LanguageService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.gatekeeperForm = this.buildFormGroup();
    this.languageService.languageObservable.subscribe((l) => {
      if (l === 'nl') {
        this.selectedLanguage = 0;
      } else {
        this.selectedLanguage = 1;
      }
    });
  }

  public onDismiss(): void {
    this.dialogRef.close(null);
  }

  public onConfirm(): void {
    const dataWrapper: DataWrapper = new DataWrapper();
    dataWrapper.reason = this.gatekeeperForm.get('reason').value;
    dataWrapper.confirmed = this.gatekeeperForm.get('isAgreed').value;
    this.dialogRef.close(dataWrapper);
  }

  private buildFormGroup() {
    return this.fb.group({
      reason: [null, { validators: [Validators.minLength(1)] }],
      isAgreed: [null],
    });
  }

  protected canShowConfirmLabel(): boolean {
    const requireReason = this.data?.requireReason;
    const reasonProvided = !!this.gatekeeperForm?.get('reason')?.value?.length;
    const requireConfirmation = this.data?.requireConfirmation;

    return (
      (requireReason && reasonProvided && requireConfirmation && this.gatekeeperForm.get('isAgreed').value) ||
      (!requireReason && requireConfirmation && this.gatekeeperForm.get('isAgreed').value) ||
      (requireReason && reasonProvided && !requireConfirmation)
    );
  }

}
