<div class="container sidesheet-commandline-container" [ngStyle]="sideSheetConfig?.style?.commandLine">
    @if (commandLineTemplate) {
        <ng-container *ngTemplateOutlet="commandLineTemplate"></ng-container>
    } @else {
        <div class="row">
            <ng-container *ngTemplateOutlet="sideSheetCommandLineDefaultTemplate"></ng-container>
        </div>
    }
</div>


<ng-template #sideSheetCommandLineDefaultTemplate>
    <ng-container *ngFor="let buttonItem of sideSheetContent?.buttons; let index = index">
        <ng-container *ngIf="buttonItem.visible">
            <ng-container
                *ngTemplateOutlet="sideSheetCommandLineButton;context:{buttonItem: buttonItem,index:index}"></ng-container>
        </ng-container>
    </ng-container>

    <ng-container *ngFor="let selectboxItem of sideSheetContent?.selectBoxes; let index = index">
        <ng-container>
            <ng-container
                *ngTemplateOutlet="sideSheetCommandLineSelectBox;context:{selectboxItem: selectboxItem,index:index}">
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-container *ngFor="let item of sideSheetContent?.checkBoxes; let index = index">
        <ng-container>
            <ng-container
                *ngTemplateOutlet="sideSheetCommandLineCheckBox;context:{checkboxItem: item,index:index}"></ng-container>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #sideSheetCommandLineButton let-item='buttonItem'>
    <div class="col-sm sidesheet-commandline-button" [ngClass]="item?.classes?.join(' ')" [ngStyle]="item?.style"
            [disabled]="item.disabled || item?.loading" (click)="onCommanlineButtonClicked(item)" [attr.disabled]="(item.disabled || item?.loading) ? '' : null">
      {{ item.name | translate }}
      <ng-container *ngIf="item?.loading">
        <mat-spinner color="primary" diameter="15" class="custom-spinner"></mat-spinner>
      </ng-container>
    </div>

</ng-template>

<ng-template #sideSheetCommandLineSelectBox let-item='selectboxItem' let-index="index">
    <mat-label>{{ item.label | translate}}</mat-label>
    <mat-select [(value)]="selectedItems[index]" [ngClass]="item?.classes?.join(' ')" [ngStyle]="item?.style">
        <ng-container *ngFor="let option of item.options">
            <mat-option [value]="option.key" (click)="onExecuteDropdownAction(item, option)">
                <span>{{ option.value }}</span>
            </mat-option>
        </ng-container>
    </mat-select>
</ng-template>

<ng-template #sideSheetCommandLineCheckBox let-item='checkboxItem'>
    <div class="c4p-form-input-small">
        <mat-checkbox [ngModel]="item.checked" (change)="onExecuteCheckboxAction($event.checked, item)"
            [disabled]="item.disabled" [labelPosition]="'after'" [ngClass]="item?.classes?.join(' ')"
            [ngStyle]="item?.style">
            {{ item.label | translate }}
        </mat-checkbox>
    </div>
</ng-template>
