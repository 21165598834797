import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideSheetHostDirective } from './side-sheet-host.directive';
import { ClickableDirective } from './clickable.directive';
import { ResizableColumnDirective } from './resizable-column-directive';
import { TooltipDirective } from './tooltip.directive';

const moduleComponents = [
  SideSheetHostDirective,
  ClickableDirective,
  ResizableColumnDirective,
  TooltipDirective
];
@NgModule({
  imports: [CommonModule,...moduleComponents],
  declarations: [],
  exports: [...moduleComponents],
  providers: [],
})
export class DirectivesModule {}
