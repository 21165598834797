import { Subject } from 'rxjs';
import { Injectable, TemplateRef, Type } from '@angular/core';
import {
  ActionType,
  Button,
  ConfirmAction,
  SideSheetConfig,
  SideSheetContent,
  SidesheetRef, 
  SideSheetPopupContent,
  SideSheetPopupConfig,
} from '../../models';
import {
  createButton,
  createDefaultSideSheetConfig,
  createPrimaryButton,
  createSecondaryButton,
  createLightButton
} from './initial-side-sheet-config';
import { SidesheetPopupRef } from '../../models/side-sheet/sidesheet-popup-ref';

@Injectable({
  providedIn: 'root',
})
export class SideSheetService {
  private sideSheetHost = new Subject<any>();
  sideSheetHost$ = this.sideSheetHost.asObservable();

  private closeSidesheetSubject = new Subject<any>();
  closeSidesheetSubject$ = this.closeSidesheetSubject.asObservable();

  private closeComponentSubject = new Subject<any>();
  closeComponentSubject$ = this.closeComponentSubject.asObservable();

  private sidesheetPopupSubject = new Subject<SidesheetPopupRef>();
  sidesheetPopupSubject$ = this.sidesheetPopupSubject.asObservable();

  private closeSidesheetPopupSubject = new Subject<any>();
  closeSidesheetPopupSubject$ = this.closeComponentSubject.asObservable();

  constructor() {}

  public loadComponent(
    component: Type<unknown>,
    content: Partial<SideSheetContent>,
    config: Partial<SideSheetConfig>,
    clearContent: boolean = false,
  ): SidesheetRef {
    const sidesheetRef: SidesheetRef = new SidesheetRef();

    this.sideSheetHost.next({
      component: component,
      content: content,
      config: config,
      sidesheetRef,
      clearContent: clearContent,
    });
    return sidesheetRef;
  }

  public closeSideSheet(): void {
    this.closeSidesheetSubject.next(true);
  }

  public closeComponent(data: any = null): void {
    this.closeComponentSubject.next(data);
  }

  public openConfirmPopup(
    title: string = 'general.labels.Back',
    content: string = 'general.messages.LeavePageMessage',
    textOk: string = 'general.labels.Yes',
    textCancel: string = 'general.labels.No',
  ): SidesheetPopupRef {
    const action: ConfirmAction = {
      textHeader: title,
      textContent: content,
      textOk: textOk,
      textCancel: textCancel
    };
    const sideSheetContent: SideSheetPopupContent = {
      confirmAction: action,
      template: null,
    };
    return this.openPopup(sideSheetContent, null);
  }

  public openTemplatePopup(sourceTemplate: TemplateRef<any>): SidesheetPopupRef {
    const sideSheetContent: SideSheetPopupContent = {confirmAction: null, template: sourceTemplate};
    return this.openPopup(sideSheetContent, null);
  }

  public openPopup(content: Partial<SideSheetPopupContent>, config: Partial<SideSheetPopupConfig>,): SidesheetPopupRef {
    const sidesheetPopupRef: SidesheetPopupRef = new SidesheetPopupRef(content, config);
    this.sidesheetPopupSubject.next(sidesheetPopupRef);
    return sidesheetPopupRef;
  }

  public closeSidesheetPopup(data) {
    this.closeSidesheetPopupSubject.next(data);
  }

  public createButton(
    name: string,
    actionType: string,
    style?: any,
    classes?: string[],
  ): Button {
    return createButton(name, actionType, style, classes);
  }
  createPrimaryButton(name: string, actionType: string): Button {
    return createPrimaryButton(name, actionType);
  }
  createSecondaryButton(name: string, actionType: string): Button {
    return createSecondaryButton(name, actionType);
  }
  createLightButton(name: string, actionType: string): Button {
    return createLightButton(name, actionType);
  }

  createCloseButton(): Button {
    return createLightButton('general.labels.Close', ActionType.CLOSE);
  }
  createBackButton(): Button {
    return createLightButton('general.labels.Back',ActionType.BACK);
  }
  createCancelButton(): Button {
    return createLightButton('general.labels.Cancel', ActionType.CANCEL);
  }
  createEditButton(): Button {
    return createSecondaryButton('general.labels.Edit', ActionType.EDIT);
  }
  createCreateButton(): Button {
    return createPrimaryButton('general.labels.Create', ActionType.CREATE);
  }
  createAddButton(): Button {
    return createPrimaryButton('general.labels.Add', ActionType.ADD);
  }
  createSaveButton(): Button {
    return createPrimaryButton('general.labels.Save', ActionType.SAVE);
  }
  createOkButton(): Button {
    return createPrimaryButton('OK', ActionType.OK);
  }
  createDeleteButton(): Button {
    return createPrimaryButton('general.labels.Delete', ActionType.DELETE);
  }

  createDefaultSideSheetConfig() {
    return createDefaultSideSheetConfig();
  }
}
