import { AfterContentInit, Component, ContentChildren, Input, QueryList, ViewEncapsulation } from '@angular/core';
import { TuulaTableActionButtonComponent } from '../tuula-table-action-button/tuula-table-action-button.component';
import { MatMenuModule } from "@angular/material/menu"
import { MatIconModule } from '@angular/material/icon';
import { NgFor, NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'app-tuula-table-action-column',
  templateUrl: './tuula-table-action-column.component.html',
  styleUrl: './tuula-table-action-column.component.scss',
  standalone:true,
  imports: [MatMenuModule,MatIconModule,NgFor,NgTemplateOutlet],
  encapsulation: ViewEncapsulation.None

})
export class TuulaTableActionColumnComponent implements AfterContentInit{

  @Input() numberOfVisibleButtons: number = 3;
  @ContentChildren(TuulaTableActionButtonComponent) actionButtons!: QueryList<TuulaTableActionButtonComponent>;

  visibleButtons: TuulaTableActionButtonComponent[] = [];
  overflowButtons: TuulaTableActionButtonComponent[] = [];

  constructor() { }

  ngAfterContentInit() {
    this.manageButtonsVisibility();
  }

  private manageButtonsVisibility() {
    const buttonsArray = this.actionButtons.toArray();
    this.visibleButtons = buttonsArray.slice(0, this.numberOfVisibleButtons);
    this.overflowButtons = buttonsArray.slice(this.numberOfVisibleButtons);
    this.overflowButtons?.forEach(button => button.overflow = true);
  }

}
