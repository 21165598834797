import { NgModule } from "@angular/core";
import {
  SideSheetComponent,
  TooltipComponent,
  TuulaTableActionButtonComponent,
  TuulaTableActionColumnComponent,
  TuulaTableComponent,
  TuulaTableFilterHeaderColumnComponent,
  TuulaTableSettingsComponent,
  TuulaTableSkeletonComponent,
} from 'tuula-common';

const moduleComponents = [
    TuulaTableComponent,
    SideSheetComponent,
    TuulaTableActionButtonComponent,
    TuulaTableActionColumnComponent,
    TuulaTableFilterHeaderColumnComponent,
    TuulaTableSettingsComponent,
    TuulaTableSkeletonComponent,
    TooltipComponent
]

@NgModule({
  imports: [...moduleComponents],
  bootstrap: [],
  declarations: [],
  exports: [...moduleComponents],
  providers: [],
  schemas: [],
})
export class ComponentsModule {}
