<div style="display: flex" (click)="$event.stopPropagation()">
  <!-- Visible Buttons -->
  <ng-container *ngFor="let button of visibleButtons">
    <ng-container *ngTemplateOutlet="button.template"></ng-container>
  </ng-container>

  @if(overflowButtons.length > 0){
    <ng-container>
      <div class="tuula-table-row-action-button" [matMenuTriggerFor]="tableRowOverflowMenu">
        <i class="ai-icon-dots-vertical tuula-action-icon"></i>
      </div>
    </ng-container>
  }
</div>

<mat-menu #tableRowOverflowMenu="matMenu" class="c4p-menu">
  <ng-container *ngFor="let button of overflowButtons">
      <ng-container *ngTemplateOutlet="button.template"></ng-container>
  </ng-container>
</mat-menu>
