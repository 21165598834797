import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../material.module';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormControlsModule } from '../form-controls';
import { GoogleChartsModule } from 'angular-google-charts';
import { AvatarComponent } from './avatar/avatar.component';
import { CardViewComponent } from './card-view/card-view.component';
import { ChartComponent } from './chart/chart.component';
import { DialogComponent } from './dialog/dialog.component';
import { GatekeeperComponent } from './gatekeeper/gatekeeper.component';
import { LabelComponent } from './label/label.component';
import { LongLabelComponent } from './long-label/long-label.component';
import { PageComponent } from './page/page.component';
import { PageContentComponent } from './page-content/page-content.component';
import { PageMenuComponent } from './page-menu/page-menu.component';
import { PageTabComponent } from './page-tab/page-tab.component';
import { PanelComponent } from './panel/panel.component';
import { PanelFormRowComponent } from './panel-form-row/panel-form-row.component';
import { PanelGridComponent } from './panel-grid/panel-grid.component';
import { PanelTitleComponent } from './panel-title/panel-title.component';
import { StatusComponent } from './status/status.component';
import { TableViewComponent } from './table-view/table-view.component';
import { TimePeriodComponent } from './time-period/time-period.component';
import { PageChildTabsComponent } from './page-child-tabs/page-child-tabs.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AvatarEditComponent } from './avatar-edit/avatar-edit.component';
import { PipesModule } from '../pipes';
import { AppBaseComponent } from './app-base/app-base.component';
import { LanguagePickerComponent } from './language-picker/language-picker.component';
import { NotificationComponent } from './notification/notification.component';
import { RolePickerComponent } from './role-picker/role-picker.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { PageInfoComponent } from './page-info/page-info.component';
import { CardGridComponent } from './card-grid/card-grid.component';
import { CardView2Component } from './card-view-2/card-view-2.component';
import { ErrorPanelComponent } from './error-panel/error-panel.component';
import { ReturnCodeDisplayComponent } from './return-code-display/return-code-display.component';
import { ObjectListComponent } from './object-list/object-list.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { DirectivesModule } from '../directives';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardWidgetComponent } from './dashboard/dashboard-widget/dashboard-widget.component';
import { WidgetDirective } from './dashboard/dashboard-widget/widget.directive';
import { DashboardSettingsComponent } from './dashboard/dashboard-settings/dashboard-settings.component';
import { DashboardService } from './dashboard/dashboard.service';
import { ErrorAlertComponent } from './error-alert/error-alert.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SlidePanelComponent } from './slide-panel/slide-panel.component';
import { ValueComponent } from './value/value.component';
import { EmptyWidget } from './dashboard/empty-widget/empty.widget';
import { PageMenuAdditionalComponent } from './page-menu-additional/page-menu-additional.component';
import { EmployeeLinkComponent } from './employee-link/employee-link.component';
import { CalculateResultComponent } from './calculate-result/calculate-result.component';
import { CollaborationUsersComponent } from './collaboration-users/collaboration-users.component';
import { CollaborationUserAvatarComponent } from './collaboration-users/collaboration-user-avatar/collaboration-user-avatar.component';
import { CarefilePageMenuComponent } from './carefile-page-menu/carefile-page-menu.component';
import { CarefilePageMenuAdditionalComponent } from './carefile-page-menu-additional/carefile-page-menu-additional.component';
import { MismatchAnswersTitleComponent } from './mismatch-answers-title/mismatch-answers-title.component';
import { MismatchAnswersHintComponent } from './mismatch-answers-hint/mismatch-answers-hint.component';
import { ServiceDeskComponent } from './service-desk-form/service-desk.component';
import { CardViewNoteComponent } from './card-view-note/card-view-note.component';
import { AgGridWrapperComponent } from './ag-grid-wrapper/ag-grid-wrapper.component';
import { AccordionComponent } from './accordion/accordion.component';
import { GridCellComponent } from './button/grid-cell.component';
import { AgGridModule } from 'ag-grid-angular';
import { DatePipe } from '@angular/common';
import {NotificationTooltipComponent} from './notification-tooltip/notification-tooltip.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NavbarMenuItemComponent } from './navbar-menu-item/navbar-menu-item.component';
import { NotificationSettingsComponent } from './notification-settings/notification-settings.component';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DialogDeleteComponent } from './dialog-delete/dialog-delete.component';
import {MedicationProfileComponent} from './medication-profile/medication-profile.component';
import { FormInputSlideToggleNdComponent, FormInputTextAreaNdComponent, SideSheetComponent } from 'tuula-common';
import { BreadcrumbStComponent } from '../standalone-components';


const moduleComponents = [
  AvatarComponent,
  AvatarEditComponent,
  CardViewComponent,
  ChartComponent,
  DialogComponent,
  DialogDeleteComponent,
  GatekeeperComponent,
  LabelComponent,
  LongLabelComponent,
  PageComponent,
  PageContentComponent,
  PageMenuComponent,
  PageTabComponent,
  PanelComponent,
  PanelFormRowComponent,
  PanelGridComponent,
  PanelTitleComponent,
  StatusComponent,
  TableViewComponent,
  TimePeriodComponent,
  PageChildTabsComponent,
  AppBaseComponent,
  LanguagePickerComponent,
  NotificationComponent,
  NotificationTooltipComponent,
  NotificationSettingsComponent,
  RolePickerComponent,
  TopBarComponent,
  UserMenuComponent,
  PageInfoComponent,
  CardGridComponent,
  CardView2Component,
  ReturnCodeDisplayComponent,
  ErrorPanelComponent,
  ObjectListComponent,
  FileUploadComponent,
  NotFoundPageComponent,
  DashboardComponent,
  DashboardWidgetComponent,
  DashboardSettingsComponent,
  WidgetDirective,
  ErrorAlertComponent,
  ChangePasswordComponent,
  SlidePanelComponent,
  ValueComponent,
  EmptyWidget,
  PageMenuAdditionalComponent,
  EmployeeLinkComponent,
  CalculateResultComponent,
  CollaborationUsersComponent,
  CollaborationUserAvatarComponent,
  CarefilePageMenuComponent,
  CarefilePageMenuAdditionalComponent,
  MismatchAnswersTitleComponent,
  MismatchAnswersHintComponent,
  ServiceDeskComponent,
  CardViewNoteComponent,
  AccordionComponent,
  AgGridWrapperComponent,
  NavbarComponent,
  NavbarMenuItemComponent,
  MedicationProfileComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FormControlsModule,
    GoogleChartsModule,
    TranslateModule,
    RouterModule,
    ImageCropperModule,
    PipesModule,
    DirectivesModule,
    TranslateModule,
    AgGridModule,
    NgxMatSelectSearchModule,
    SideSheetComponent,
    FormInputSlideToggleNdComponent,
    FormInputTextAreaNdComponent,
    BreadcrumbStComponent
  ],
  declarations: [...moduleComponents, GridCellComponent],
  exports: [...moduleComponents],
  providers: [DashboardService, DatePipe],
})
export class GeneralComponentsModule {}
