import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, distinctUntilChanged, finalize, startWith, Subject, takeUntil } from 'rxjs';
import { DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT, TIME_REGEX } from '../../../constants';
import { TableColumn } from '../../../models';
import { GeneralUtil } from '../../../utilities';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgClass, NgStyle } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-tuula-table-filter-header-column',
  templateUrl: './tuula-table-filter-header-column.component.html',
  styleUrl: './tuula-table-filter-header-column.component.scss',
  standalone: true,
  imports: [MatFormFieldModule,
    ReactiveFormsModule, MatDatepickerModule, NgClass,
    MatSelectModule, NgxMatSelectSearchModule,
    TranslateModule, MatIconModule,
    FormsModule, MatInputModule, NgStyle,
  ],
})
export class TuulaTableFilterHeaderColumnComponent implements OnInit, OnDestroy {

  @Input() column!: TableColumn;
  @Output() filterChanged = new EventEmitter<{ column: TableColumn }>();
  @Output() filterCleared = new EventEmitter<{ column: TableColumn }>();

  private readonly destroyed$ = new Subject<boolean>();

  valueControl = new FormControl();
  dateIntervalGroup: FormGroup = new FormGroup({
    startDateControl: new FormControl(),
    endDateControl: new FormControl(),
  });
  searchControl: FormControl = new FormControl();
  isSearchResultLoading = false;
  protected readonly onKeydown = TIME_REGEX;

  get startDateControl() {
    return this.dateIntervalGroup.get('startDateControl') as FormControl;
  }

  get endDateControl() {
    return this.dateIntervalGroup.get('endDateControl') as FormControl;
  }

  constructor() { }


  ngOnInit(): void {
    if (this?.column?.enableFiltering) {

      if (this?.column?.filter?.type === 'select') {
        this.setDefaultSelectParameters(this?.column?.filter?.options);
        if (this?.column?.filter?.loadOptions) {
          this.subscribeSearchTextChanges();
        }
      }

      if (this.column.filter.type === 'time') {
        this.valueControl.addValidators(this.validateTime);
      }

      if (this.column?.filter?.interval) {
        this.startDateControl.patchValue(this.column?.filter?.filterValue);
        this.endDateControl.patchValue(this.column?.filter?.filterValueIntervalEnd);
      } else {
        this.valueControl.patchValue(this.column?.filter?.filterValue);
      }
      this.subcribeValueControlChanges();
      this.subscribeDateControlChanges();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  subcribeValueControlChanges() {
    this.valueControl.valueChanges.pipe(
      takeUntil(this.destroyed$)
    ).subscribe((val) => {
      if (this.valueControl.valid || !this.valueControl.value) {
        this.column.filter.filterValue = val;
        this.filterChanged.emit({ column: this.column });
      }
      if (this?.column?.filter?.type === 'select') {
        this.updateSelectedOptions();
        this.filterChanged.emit({ column: this.column });
      }
    });
  }

  subscribeDateControlChanges() {
    this.startDateControl.valueChanges.pipe(
      takeUntil(this.destroyed$)
    ).subscribe((val) => {
      this.column.filter.filterValue = val;
      this.filterChanged.emit({ column: this.column })
    });

    this.endDateControl.valueChanges.pipe(
      takeUntil(this.destroyed$)
    ).subscribe((val) => {
      this.column.filter.filterValueIntervalEnd = val;
      this.filterChanged.emit({ column: this.column })
    });
  }

  subscribeSearchTextChanges() {

    this.searchControl?.valueChanges.pipe(
      startWith(this.searchControl?.value),
      distinctUntilChanged(),
      debounceTime(DEFAULT_REQUEST_DEBOUNCE_TIME_AMOUNT),
      takeUntil(this.destroyed$),
    ).subscribe((searchVal) => {
      this.searchText(searchVal);
    });
  }

  searchText(searchVal) {
    this.isSearchResultLoading = true;
    this.column?.filter?.loadOptions(this.column, searchVal).pipe(
      finalize(() => (this.isSearchResultLoading = false)),
    ).subscribe((result) => {
      this.setDefaultSelectParameters(result);
    });
  }

  setDefaultSelectParameters(defaultOptions) {
    this.column.filter.options = defaultOptions ?? [];
    this.column.filter.optionLabelProperty = this?.column?.filter?.optionLabelProperty ?? 'label';
    this.column.filter.optionValueProperty = this?.column?.filter?.optionValueProperty ?? 'value';
    if (this.column.filter.filterValue) {
      this.addMissingSelectedOptions();
    }
  }

  updateSelectedOptions() {
    if (this.column?.filter?.multiSelection) {
      this.column.filter.selectedOptionObjects = this.column?.filter?.filterValue?.map(val => this.getSelectedObject(val)).filter(val => val != null);
    } else {
      this.column.filter.selectedOptionObject = this.getSelectedObject(this.column.filter.filterValue);
    }
  }

  getSelectedObject(value) {
    return this.column.filter.options
    .map(opt => GeneralUtil.copyPropertiesOfObject(opt, [this.column.filter.optionLabelProperty, this.column.filter.optionValueProperty]))
    .find(opt => opt[this.column.filter.optionValueProperty] === value) ?? null;
  }

  addMissingSelectedOptions() {

    if (this.column?.filter?.multiSelection) {
      this.column?.filter?.filterValue?.forEach(element => {
        const selected = this.column.filter.selectedOptionObjects?.find(opt => opt[this.column.filter.optionValueProperty] === element)
        this.addMissingSelectedOption(element, selected);
      });
    } else {
      this.addMissingSelectedOption(this.column.filter.filterValue, this.column.filter.selectedOptionObject);
    }
  }

  addMissingSelectedOption(value, option) {
    const found = this.column.filter.options.find(opt => opt[this.column.filter.optionValueProperty] === value);
    if (!found) {
      this.column.filter.options.unshift(option);
    }
  }

  onClearFilters() {
    this.filterCleared.emit();
  }

  public clearFilters() {
    this.valueControl.setValue(null);
    this.startDateControl.setValue(null);
    this.endDateControl.setValue(null);
    this.searchControl.setValue(null);
  }

  allowOnlyTimeInput(event: KeyboardEvent) {
    const allowedChars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ':'];
    const specialKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete', 'Home', 'End'];

    if (specialKeys.includes(event.key)) {
      return;
    }

    if (!allowedChars.includes(event.key)) {
      event.preventDefault();
    }
  }

  validateTime(control: FormControl) {
    const value = control.value;
    const timeRegex = /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])$/;
    return timeRegex.test(value) ? null : { invalidTime: true };
  }

  formatTimeInput(event: any) {
    let input = event.target.value;

    if (input === '') {
      this.valueControl.setValue('');
      return;
    }

    const cursorPosition = event.target.selectionStart;
    const isDeleting = event.inputType === 'deleteContentBackward';

    input = input.replace(/[^0-9:]/g, '');

    const parts = input.split(':');

    if (parts[0] && parseInt(parts[0], 10) > 23) {
      parts[0] = '23';
    }

    if (parts[1] && parseInt(parts[1], 10) > 59) {
      parts[1] = '59';
    }

    if (!isDeleting && input.length === 2 && input.indexOf(':') === -1) {
      input += ':';
    }
    if (input.length > 5) {
      input = input.slice(0, 5);
    }

    event.target.value = input;
    this.valueControl.setValue(input);
    if (isDeleting) {
      event.target.setSelectionRange(cursorPosition, cursorPosition);
    }
  }

}
